import React, { useEffect,useState } from 'react'
import {motion} from "framer-motion"
import hpic from '../images/hpic1-min.png'
import hpic2 from '../images/hpic2.png'
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';


 const Contact =()=> {

  const [fname, setfName] = useState("First Name*");
  const [lname, setlName] = useState("Last Name*");
  const [email, setemail] = useState("Email*");
  const [pnum, setpnum] = useState("Number*");
  const [pmessage, setpmessage] = useState("Message*");

  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Hire the Best IT Professionals | Contact Us</title>
      <meta name="description" content="Looking to hire top IT professionals? Contact us today to find skilled IT experts for your business needs. Fast, reliable, and efficient hiring solutions."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section  className='header_top'>
      <div className='headertp2'  >
      <br /><br />
        <div className='h_row'>
         <div className='h_col'>
            <br /><br /><br />
        <h1>Contact Us</h1>
<br />
  <img src={hpic} alt="paint" />
  <br /><br />
     <h2>Get in Touch with Us Today. Let's Connect and Build Your Dream Team. 

</h2>
   <br />

         </div>
         <div className='h_col2a'>
             <img src={hpic2} alt="pic" />
         </div> 
        </div>
      </div>
      </section>
<br /><br /><br />
<br /><br /><br /><br />
<section className=''>
<div className='con_sec'>
<div className='con_row'>
    <div className='con_col'>
        <h2>Reach Out to Us </h2>
        <p>Fill out the form below, and our team will get<br /> back  to you  promptly to discuss your<br /> hiring needs  or job opportunities. </p>
        <br />
<a href=""><i class="ri-map-pin-fill"></i>&nbsp; 24285 Katy Freeway,<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suite 300 Katy TX 77494 </a>
<br />
<a href="+1 234 678 9108 99"><i class="ri-phone-fill"></i> &nbsp; +1 234 678 9108 99</a>
<br />
<span style={{display:"flex", alignItems:"center"}}><i class="ri-mail-fill"></i>&nbsp;<a  href="emma@hiringgate.com"> emma@hiringgate.com</a></span>

<br />
<h2>Let us help</h2>
<p>✓ Find top talent. </p>
<p>✓ Discover remote jobs. </p>
<p>✓ Answer your questions. </p>
<p>✓ Provide support.  </p> 
    </div>
    <div className='con_col'>
        <div className='for_m'>
         <div className='for_m1'>
           
           <div className='for_row'>
            <div className='for_col' >
                <input type="text" value={fname}           onChange={(e) => setfName(e.target.value)}
 id='colinpp'/>
            </div>
            <div className='for_col'>
                <input type="text"  value={lname}           onChange={(e) => setlName(e.target.value)}/>
            </div>

           </div>
           <br />
           <input type="text"  value={email}           onChange={(e) => setemail(e.target.value)}/>
           <br /><br />
           <input type="text" id="fname" value={pnum}           onChange={(e) => setpnum(e.target.value)}/>
           <br /><br />
           <textarea id="" value={pmessage}           onChange={(e) => setpmessage(e.target.value)} rows="4" cols="50">

           </textarea>
           <br /><br />
           <button>Send Message</button>
         </div>
         <br />
        </div>
    </div>
</div>
</div>
</section>
<br /><br />

 <section className='sec__last_1'>
  <div className='sec__last1m'>
  <h2>Start Hiring with us today</h2>
  <p>Imagine scaling without the headache.</p>
  <button>Get Started</button>
  </div>
 </section>
<br /><br />
 <section className=''>
  <div className='lst_sec'>
   <h2>What Our Clients Say…</h2>
   <br /><br />
   <div className='lst_row'>
    
    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>

    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>

    <div className='lst_col'>
     <div className='ro_m'>
      <div className='ro_col1'><img src="" alt="" /></div>
      <div className='ro_col2'>
        <h3>Cali Huffman</h3>
        <p>Student on UI / UX designer course</p>
      </div>
     </div>
     <p>Nisi dui ut imperdiet convallis orci. Id viverra urna cras quis amet, accumsan, iaculis donec nunc. In gravida purus felis ullamcorper vitae enim sed adipiscing pellentesque. </p>
    </div>
   </div>


  </div>
 </section>
    </div>
  )
}
export default Contact;