import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo3.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="footer_row">

        <div className='footer_coll' >
          <img src={logo} alt="logo" />
          <p>We connect companies across the USA with top IT talent, ensuring a seamless and efficient hiring process for both employers and candidates.</p>
          <br />
            
        </div>
        <div className='footer_coll' >
          <a href="about">About</a>
         <br />  <a href="contact">Contact Us</a>
         <br /><a href='career'>Career</a>
        </div>
        <div className='footer_coll'  >
        <h2>Get notifications on Email</h2>
        
        <p>The latest notifications news, articles, sent to your inbox weekly.</p>
        
        <br />
        <div className='rooow'>
        
          <div className='coooola'>
            <input type="text"  value="Email Address" />
          </div>
          <div className='coooolb'>
          Subscribe
          </div>
        </div>
<br />
        <i style={{color:"#007C80",fontSize:"25px",}} class='bx bxl-facebook-circle'></i> &nbsp;
        <i style={{color:"#007C80",fontSize:"25px"}} class='bx bxl-instagram-alt' ></i>&nbsp;&nbsp;
        <i style={{color:"#007C80",fontSize:"25px"}} class='bx bxl-twitter' ></i>&nbsp;&nbsp;
        <i  style={{color:"#007C80",fontSize:"25px"}} class='bx bxl-linkedin-square'></i>
        </div>

 
        </div>
        <hr style={{width:"100%",color:"#fff",height:"1px"}}/>
        <p style={{textAlign:"center",color:"#ffff",fontFamily:"Manrope",fontWeight:"500",paddingBottom:"20px"}}>2024 @ Hiring Gate. All rights reserved.</p>
        </div>

      </section> 
    </>
  )
}
export default Footer;